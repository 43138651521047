import React from 'react';
import './DogProfileCard.css'; // Import the CSS for styling

import ing_1 from '../../../assets/size_img_1.png'; 
import ing_2 from '../../../assets/size_img_2.png'; 
import ing_3 from '../../../assets/size_img_3.png';

import calorieImg from '../../../assets/rec_img_1.png'; 
import weightImg from '../../../assets/rec_img_2.png'; 
import cupImg from '../../../assets/rec_img_3.png'; 

const dogProfiles = [
  {
    fileName: ing_2,
    name: 'Patchy',
    details: '7kg | Senior | Lazy Bones | Loves Lamb',
    cals: '331 cals',
    weight: '103g',
    cups: '0.9 cups',
    frequency: '5 kg Luxe Lamb every 48 days',
    pricePerDay: '$1.41 a day',
    calsIcon: calorieImg,
    weightIcon: weightImg,
    cupsIcon: cupImg
  },
  {
    fileName: ing_3,
    name: 'Mimi',
    details: '4.5kg | Puppy | Typical | Loves Lamb',
    cals: '606 calories',
    weight: '188g',
    cups: '1.7 cups',
    frequency: '7.5 kg Luxe Lamb every 39 days',
    pricePerDay: '$2.48 a day',
    calsIcon: calorieImg,
    weightIcon: weightImg,
    cupsIcon: cupImg
  },
  {
    fileName: ing_1,
    name: 'Tonka',
    details: '25kg | Adult | Typical | Loves Chicken',
    cals: '1096 calories',
    weight: '324g',
    cups: '2.9 cups',
    frequency: '15 kg Chicken Dog Box every 46 days',
    pricePerDay: '$3.69 a day',
    calsIcon: calorieImg,
    weightIcon: weightImg,
    cupsIcon: cupImg
  }
];

const DogProfileCard: React.FC<{profile: any}> = ({ profile }) => (
  <div className="dog-profile-card">
    <div className="img-wrapperdog">
      <img src={profile.fileName} alt={profile.name} title={profile.name} />
    </div>
    <h3 className="hdr">{profile.name}</h3>
    <p className="details">{profile.details}</p>
    <div className="dog-profile-info">
      <div>
        <img src={profile.calsIcon} alt="Calories" />
        <p>{profile.cals}</p>
      </div>
      <div>
        <img src={profile.weightIcon} alt="Weight" />
        <p>{profile.weight}</p>
      </div>
      <div>
        <img src={profile.cupsIcon} alt="Cups" />
        <p>{profile.cups}</p>
      </div>
    </div>
    <p className="frequency">{profile.frequency}</p>
    <p className="pricePerDay">{profile.pricePerDay}</p>
  </div>
);

const DogProfileSection: React.FC = () => (
  <div className="def-w-max">
    <div className="ing-hdr-wrap text-center">
        <h2 className="mgn-bot-20">One size doesn’t fit all.</h2>
        <p>Your dog is unique. They deserve a personalised feeding plan.</p>
        <p>Here's are some examples to give you the idea:</p>
      </div>
  <div className="dog-profile-section">
    {dogProfiles.map((profile, index) => (
      <DogProfileCard key={index} profile={profile} />
    ))}
  </div>
  </div>
);

export default DogProfileSection;
