import React from 'react';
import { navigate } from 'gatsby';
import { Button } from 'react-bootstrap';
import loadable from '@loadable/component';

import star from '../assets/star.png';
import num_1 from '../assets/num_1.png';
import num_2 from '../assets/num_2.png';
import num_3 from '../assets/num_3.png';
import MainLayout from '../layouts/mainLayout';
import { OptimizedImage } from '../components/common/OptimizedImage';
import { isLoggedIn } from '../services/auth.api';
import InfoItemsCard from '../components/common/InfoItemsCard/InfoItemsCard';
import CardWrapper from '../components/Home/CardWrapper';
import DogProfileSection from '../components/Home/DogProfileCard/DogProfileSection';

import ImageGallery from 'react-image-gallery';
import strImg2 from '../assets/CdbBoxClosed.jpg';
import strImg3 from '../assets/cdbBox.png';
import strImg4 from '../assets/bowl.png';
import strImg5 from '../assets/about_fab.png';
import pointerIcon from '../assets/pointerIcon.png';

import boxPlusBeefTreat from '../assets/boxPlusBeefTreat.png';
import AAFCO from '../assets/AAFCO.png';
import vetApproved from '../assets/vetApproved.png';

const JoinFurFamily = loadable(() => import('components/JoinFurFamily'));

const FeaturedAboutSection = loadable(() =>
  import('components/common/FeaturedAboutSection'),
);
const HowItWorksSection = loadable(() =>
  import('components/Home/HowItWorksSection'),
);

const HowAboutSection = loadable(() => import('components/Home/HowAboutSection'));

const onGetStarted = () => {
  navigate(isLoggedIn() ? '/account/dashboard/' : '/getstarted/');
};

const onShop = () => {
  navigate('/products/');
};

const infoItems = [
  {
    imgSrc: num_1,
    imgAlt: 'Step 1',
    imgTitle: 'Introduce your Furbaby',
    title: 'Introduce your Furbaby',
    description:
      'Your dog’s Age, Weight and Activity level will tell us how many calories a day they need.',
  },
  {
    imgSrc: num_2,
    imgAlt: 'Step 2',
    imgTitle: 'Design Feeding Plan',
    title: 'We’ll design their feeding plan',
    description:
      'Based on the optimal daily diet for your specific dog, we will create a personalised daily meal plan.',
  },
  {
    imgSrc: num_3,
    imgAlt: 'Step 3',
    imgTitle: 'Start Subscription',
    title: 'Start your subscription',
    description:
      'Your dog’s daily meal requirements will determine how much and how often they need an order.',
  },
];

const images = [
  {
    original: strImg2,
    thumbnail: strImg2,
  },
  // {
  //   original: strImg3,
  //   thumbnail: strImg3,
  // },
  {
    original: strImg4,
    thumbnail: strImg4,
  },
  {
    original: strImg5,
    thumbnail: strImg5,
  },
];

export default (props) => {
  const isTester = props.isTester;
  return (
    <MainLayout withTitle>
      <div>
        <div className="about-section-item def-w-max mgn-top-50 ">
          <div className="row">
            <div className="col-12 col-sm-6 ">
              <div className="txt-wrap-sect">
                <h2 className="para-txt mgn-bot-30 sectionAlignment">
                  Fabulous, natural dog food, delivered right to your door!
                </h2>
                {/* OptimizedImage component for mobile only */}
                <div className="img-view-mobile">
                  <div className="col-12 col-sm-6">
                    <div className="op-img-wrap">
                      <OptimizedImage fileName="section_img_3.png" />
                    </div>
                  </div>
                </div>
                <div className="tick-list">
                  <ul>
                    <li>
                      Real food, made fresh in New Zealand
                    </li>
                    <li>
                      Natutal nutrients your dog will thrive on
                    </li>
                    <li>
                      Tailored delivery schedules, right to your door.
                    </li>
                  </ul>
                </div>


                <div className="btn-wrapper sectionAlignment">
                  <Button
                    className="btn-def align_center sectionAlignment"
                    onClick={onGetStarted}>
                    {isLoggedIn()
                      ? 'Dashboard'
                      : 'Create my furbaby meal plan'}
                  </Button>
                </div>

              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="op-img-wrap img-view-desktop">
                <OptimizedImage fileName="section_img_3.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturedAboutSection />

      <div className="about-wrapper">
        <div className="about-section-item def-w-max row align-items-stretch">
          <div className="col-12 col-sm-6 d-flex white-bg-card topRowWrapper">
            <div className="flex-fill imageWrapper">
              <ImageGallery
                items={images}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={images.length > 1}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 d-flex">
            <div className="white-bg-card flex-fill offer-section" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
              <div>
                <p className="sectionAlignment" style={{ color: '#a9e3f4', fontSize: '18px', fontWeight: 'bold' }}>LIMITED TIME OFFER</p>
                <h3>
                  Get <span style={{ color: '#fb6a43' }}>20% OFF</span> Your First Box
                </h3>
                <p>
                  Plus <strong>FREE</strong> beef liver training treat when you sign up now!
                </p>
                <div className="image-wrapper">
                  <img src={boxPlusBeefTreat} alt="Box and Beef Treat" className="offer-image" />
                </div>
                <div className="button-with-pointer">
                  <Button className="btn-def mgn-top-30" onClick={onGetStarted}>
                    Create my furbaby meal plan
                  </Button>
                  <div className="pointer-icon-wrapper">
                    <img src={pointerIcon} alt="Pointer Icon" className="pointer-icon" />
                  </div>
                </div>
                <div className="row align-items-center mt-4">
                  <div className="col-auto rating-section">
                    <div className="row justify-content-center align-items-center">
                      <h2 className="col-auto rating-score">4.9</h2>
                      <div className="col-auto rating-details">
                        <div className="stars">
                          {[...Array(5)].map((_, index) => (
                            <img src={star} alt="star" className="star" key={index} />
                          ))}
                        </div>
                        <div className="review-count">(148 REVIEWS)</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <img src={AAFCO} alt="AAFCO Approved" className="approval-image" />
                  </div>
                  <div className="col-auto">
                    <img src={vetApproved} alt="Vet Approved" className="approval-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowAboutSection />
      <div className="features-wrapper mgn-sm-top-100">
        <div className="container-fluid">
          <div className="teat-bg mgn-top-30">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30 sectionAlignment">
                      Fabulous dry food your dog will thrive on, not just survive.
                    </h2>
                    <div className="hdr-para-wrapper">
                      <h4>Real food, made fresh in New Zealand</h4>
                      <p className="para-txt">
                        Why buy imported pet food when you can can buy amazing NZ
                        made dog food, with fabulous local ingredients? We make our
                        super healthy dog food in NZ, using all natural ingredients
                        and deliver it straight to you. Its fresher and better for
                        your dog.
                      </p>
                    </div>
                    <div className="hdr-para-wrapper ">
                      <h4>Nutrients your dog will thrive on.</h4>
                      <p className="para-txt">
                        We design food for your dog to thrive. Our grain free dog
                        food exceeds all AAFCO (global standard) specifications.
                        Most store bought foods simply ‘meet’ the standards for dogs
                        to survive on... but your dog deserves the best dog food NZ
                        has to offer!
                      </p>
                    </div>
                    {/* <Button
                      className="btn-def mgn-top-30"
                      onClick={() =>
                        navigate(
                          isLoggedIn() ? '/account/dashboard/' : '/getstarted/',
                        )
                      }
                    >
                      {isLoggedIn() ? 'Dashboard' : 'Build your feeding plan'}
                    </Button> */}
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="op-img-wrap">
                    <OptimizedImage fileName="about_fab.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HowItWorksSection pagename="aboutPage" />
        </div>
      </div>
      <div className="ingr-col-wrapper">
        <div className="container-fluid mgn-top-50">
          <DogProfileSection />
        </div>
        <div className="btn-wrapper text-center">
          <Button className="btn-def mgn-top-30" onClick={onGetStarted}>
            Create my furbaby meal plan
          </Button>
        </div>
      </div>
      <div className="testimonial-wrapper">
        <div className=" text-center">
          <h2>
            Loved by Thousands of New Zealand <span className="slash-or">Furbabies</span>
          </h2>
          <div className="rating-section">
            <div className="row justify-content-center align-items-center">
              <h2 className="col-auto rating-score">4.9</h2>

              <div className="col-auto rating-details">
                <div className="stars">
                  {[...Array(5)].map((_, index) => (
                    <img src={star} alt="star" className="star" key={index} />
                  ))}
                </div>
                <div className="review-count">(148 REVIEWS)</div>
              </div>
            </div>
          </div>
          <CardWrapper />
        </div>
      </div>

      <JoinFurFamily pagename="aboutPage" page_id="about_page_footer" />
    </MainLayout>
  );
};
